<template>
  <div class='container-md pt-6 user-select-none'>
    <form>
      <h1 class='mb-6'>Limited and out-of-stock SKUs</h1>
      <div class='bg-light pa-3'>
        <div class='row mb-3'>
          <div class='col-12'>
            <BsSupplierSelect placeholder='Choose a branch' @change="on_supplier_select" class='bg-white' :class='this.supplier_select_error ? "is-invalid" : ""'/>
            <div class="invalid-feedback">
              {{supplier_select_error}}
            </div>
          </div>
        </div>

        <div class='row mb-3'>
          <div class='col-12 col-md-6'>
            <div class="form-group row mb-2">
              <label for='in_stock_threshold' class='col-sm-8 col-form-label d-flex align-items-center'>How many units is considered in stock?</label>
              <div class='col-sm-4'>
                <input type="number" class="form-control" id="in_stock_threshold" min='1' step='1' v-model="form.in_stock_threshold" placeholder="Enter threshold">
              </div>
            </div>
            <!-- <p>SKUs sold by weight can be configured <a href='/skus?filter=per_kg'>here</a></p> -->
          </div>
        </div>

        <div class='row'>
          <div class='col-12 col-md-6'>
            <div class='mb-3 form-check'>
              <input id='show_out_of_stock' type='checkbox' class='form-check-input' v-model='form.show_out_of_stock'>
              <label for='show_out_of_stock' class='form-check-label'>Show out-of-stock SKUs?</label>
            </div>
            <div v-if='this.form.show_out_of_stock' data-controller="hello" class='pl-8'>
              <div class='mb-3 form-check'>
                <input id='show_top_sellers' type='checkbox' class='form-check-input' :checked='form.show_top_sellers' @change='(v) => toggle_show_top_sellers(v.target.checked)'>
                <label for='show_top_sellers' class='form-check-label'>Show top seller SKU when out-of-stock?</label>
              </div>

              <div class='mb-3 form-check'>
                <input id='show_all_out_of_stock' type='checkbox' class='form-check-input' :checked='form.show_all_out_of_stock' @change='(v) => toggle_show_all_out_of_stock(v.target.checked)'>
                <label for='show_all_out_of_stock' class='form-check-label'>Show any SKU when out-of-stock?</label>
              </div>
            </div>
          </div>
          <div class='col-12 col-md-6'>
            <div v-if='form.show_out_of_stock && form.show_top_sellers' class='card'>
              <div class='card-header'>{{top_sellers_count}} top seller SKUs</div>
              <div class='card-body'>
                <p>To reset top sellers, upload a .CSV file with a barcode column below</p>

                <form class="form-inline mb-4" enctype="multipart/form-data">
                  <input type="file" name="csv" id="bulk-top-seller" style="width: 15rem;" @change="uploadTopSellers"/>
                </form>

                <div v-if="uploadMessage" class='alert alert-info mb-0'>
                  {{ uploadMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='row'>
          <div class='col-12 col-md-6'>
            <div class='mb-3 form-check'>
              <input id='show_low_stock' type='checkbox' class='form-check-input' v-model='form.show_low_stock'>
              <label for='show_low_stock' class='form-check-label'>Show limited stock SKUs?</label>
            </div>
            <div v-if='this.form.show_low_stock' class='pl-8'>
              <div class='mb-3 form-check'>
                <input id='show_low_stock_label' type='checkbox' class='form-check-input' v-model='form.show_low_stock_label'>
                <label for='show_low_stock_label' class='form-check-label'>Show limited stock label on SKU listings?</label>
              </div>

              <div class='mb-3 form-check'>
                <input id='low_stock_orderable' type='checkbox' class='form-check-input' v-model='form.low_stock_orderable'>
                <label for='low_stock_orderable' class='form-check-label'>Are limited stock offers orderable?</label>
              </div>
            </div>
          </div>
        </div>
        <div class='row'>
          <div class='col-12 col-md-6'>
            <button @click='save_changes' :disabled='saving' class='ml-auto btn btn-primary btn-block'>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import BsSupplierSelect from '../bs_supplier_select'
import api from 'manager/services/base'

export default {
  components: {BsSupplierSelect: BsSupplierSelect},

  data() {
    return {
      saving: false,
      supplier_id: null,
      supplier_select_error: null,
      top_sellers_count: 0,
      uploadMessage: '',
      form: {
        show_out_of_stock: false,
        show_top_sellers: false,
        show_all_out_of_stock: false,
        show_low_stock: false,
        show_low_stock_label: false,
        low_stock_orderable: false,
        in_stock_threshold: 10
      }
    }
  },

  computed: {
    api_endpoint() { return api.api_endpoint() }
  },

  methods: {
    on_supplier_select(supplier) {
      if (!!supplier) {
        api.fetch_supplier(supplier.id)
          .then((supplier) => {
            this.supplier_id = supplier.id
            this.supplier_select_error = null
            this.reset_form(supplier.stock_config)
          })
        api.fetch_top_sellers_count(supplier.id)
          .then((response) => {
            this.top_sellers_count = response
          })
      } else {
        this.top_sellers_count = 0
        this.supplier_id = null
        this.reset_form()
      }
    },

    // on_suppliers_select(suppliers) {
    //   if (suppliers.length > 0) {
    //     api.fetch_supplier(suppliers[suppliers.length - 1].id)
    //       .then((supplier) => { this.reset_form(supplier.stock_config) })
    //   } else {
    //     this.reset_form()
    //   }
    // },

    reset_form(data = {}) {
      this.form.show_out_of_stock = data.show_out_of_stock || false
      this.form.show_top_sellers = data.show_top_sellers || false
      this.form.show_all_out_of_stock = data.show_all_out_of_stock || false
      this.form.show_low_stock = data.show_low_stock || false
      this.form.show_low_stock_label = data.show_low_stock_label || false
      this.form.low_stock_orderable = data.low_stock_orderable || false
      this.form.in_stock_threshold = data.in_stock_threshold || 10
    },

    save_changes() {
      this.saving = true
      let data = {
        supplier: {
          stock_config: {
            show_out_of_stock: this.form.show_out_of_stock,
            show_top_sellers: this.form.show_top_sellers,
            show_all_out_of_stock: this.form.show_all_out_of_stock,
            show_low_stock: this.form.show_low_stock,
            show_low_stock_label: this.form.show_low_stock_label,
            low_stock_orderable: this.form.low_stock_orderable,
            in_stock_threshold: this.form.in_stock_threshold
          }
        }
      }
      api.update_supplier(this.supplier_id, data)
        .then((response) => {
          if (!!response.message) {
            this.supplier_select_error = response.message
          } else {
            this.supplier_select_error = null
          }
        })
        .finally(() => { this.saving = false })
    },

    toggle_show_top_sellers(value) {
      this.form.show_top_sellers = value
      if (!!value) { this.form.show_all_out_of_stock = false }
    },

    toggle_show_all_out_of_stock(value) {
      this.form.show_all_out_of_stock = value
      if (!!value) { this.form.show_top_sellers = false }
    },

    uploadTopSellers(event) {
      const file = event.target.files[0]
      if (!file || !this.supplier_id) {
        return
      }

      const formData = new FormData()
      formData.append('csv', file)

      fetch(`${this.api_endpoint}/suppliers/${this.supplier_id}/upload_top_sellers`, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
          'http-x-auth': this.$store.state.user.id
        }
      })
      .then(response => response.json())
      .then(data => {
        this.uploadMessage = 'Upload successful'
        return api.fetch_top_sellers_count(this.supplier_id)
      })
      .then(count => {
        this.top_sellers_count = count
      })
      .catch(error => {
        this.uploadMessage = 'Upload failed: ' + error.message
      })
      .finally(() => {
        event.target.value = '' // Reset file input
      })
    }
  }
}
</script>
