import Vue from 'vue';
import VueRouter from 'vue-router';
import router from '../manager/router';
import store from '../manager/store';
import App from '../manager/app.vue';
import vuetify from '../plugins/vuetify';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import vuedraggable from 'vuedraggable';
import Paginate from 'vuejs-paginate';

Vue.component('paginate', Paginate);
Vue.component('vue-select', vSelect);
Vue.component('vuedraggable', vuedraggable);
Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#vue-app',
    router,
    store,
    vuetify,
    render: (h) => h(App)
  });
});
