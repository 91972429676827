import VueRouter from "vue-router"

import kpis from './components/page/kpis.vue'
import orders_index from './components/page/orders_index.vue'
import order_show from './components/page/order.vue'
import not_found from './components/page/404.vue'
import login from './components/page/login'
import sign_up from './components/page/sign_up'
import price_import from './components/page/price_import'
import suppliers_index from './components/page/suppliers/index'
import combos from './components/page/sellables/combos'
import combos_edit from './components/page/sellables/combos_edit'
import auth from './services/auth'
import store from './store'
import sellables from './components/page/sellables/index'
import sellables2 from './components/page/sellables/index2'
import leaflets_index from './components/page/leaflets'
import payments_index from './components/page/payments'
import discount_codes from './components/page/discount_codes'
import low_and_no_stock from './components/page/low_and_no_stock'

require_auth = (to, from, next) ->
  if not auth.logged_in()?
    next { path: '/login', query: { redirect: to.fullPath } }
  else next()

require_invite = (to, from, next) ->
  has_access = to.query.invite_id? and to.query.email?
  if not has_access then next { path: '/login' } else next()

export default new VueRouter {
  mode: 'history',
  routes: [
    { path: '/', component: orders_index, beforeEnter: require_auth },
    { path: '/orders/:id', component: order_show, beforeEnter: require_auth },
    { path: '/kpis', component: kpis, beforeEnter: require_auth },
    { path: '/price_import', component: price_import, beforeEnter: require_auth },
    { path: '/suppliers', component: suppliers_index, beforeEnter: require_auth },
    { path: '/combos', component: combos, beforeEnter: require_auth },
    { path: '/combos/:id', component: combos_edit, beforeEnter: require_auth, props: true },
    { path: '/skus', component: sellables, beforeEnter: require_auth },
    { path: '/low_and_no_stock', component: low_and_no_stock, beforeEnter: require_auth},
    { path: '/skus2', component: sellables2, beforeEnter: require_auth },
    { path: '/leaflets', component: leaflets_index, beforeEnter: require_auth },
    { path: '/payments', component: payments_index, beforeEnter: require_auth },
    { path: '/discount_codes', component: discount_codes, beforeEnter: require_auth}
    # account routes
    { path: '/sign_up', component: sign_up, props: true, beforeEnter: require_invite },
    { path: '/login', name: 'auth', component: login },
    { path: '/logout', beforeEnter: (to, from, next) ->
      store.commit 'user/set_user', {}
      auth.logout()
      next('/login')
    }
    { path: "/:catchAll(.*)", component: not_found }
  ]
}
